import React, { Component } from 'react';
import styled from "styled-components";
import galleryMeme from "../../meme/galleryMeme.png";

const IconImage = styled.img`
  height: 20rem;
  width: 25rem;
`;

class Gallery extends Component {
  state = {};

  render() {
    return <div>
      <IconImage src={galleryMeme} alt="galleryMeme" />
      </div>;
  }
}

export default Gallery;