import React, { Component } from 'react';
import styled from "styled-components";
import contactMeme from '../../meme/contactMeme.png'

const IconImage = styled.img`
  height: 18rem;
  `;

class Contact extends Component {
  state = {};




  render() {
    return <div>
      <IconImage src={contactMeme} alt="contactMeme" />
      </div>;
  }
}

export default Contact;