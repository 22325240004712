import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from './components/navbar'

class App extends Component {
  render() {
    return <React.Fragment>
        <div className="App">
          {/* <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <h1>Title</h1>
            <p>
              Edit <code>src/App.js</code> and save to reload.
            </p>
            <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
              Learn React
            </a>
          </header> */}
        <Navbar />
        </div>
      </React.Fragment>;
  }
}

export default App;
