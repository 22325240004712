import React, { Component } from 'react';
import styled from "styled-components";
import blogMeme from "../../meme/blogMeme.png";

const IconImage = styled.img`
  height: 18rem;
`;


class Blog extends Component {
  state = {};

  render() {
    return <div>
        <IconImage src={blogMeme} alt="blogMeme" />
      </div>;
  }
}

export default Blog;