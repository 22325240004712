import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import styled from "styled-components";


import Home from "../home";
import Gallery from "../gallery";
import Blog from "../blog";
import Contact from "../contact";
import profile from "../../icons/profile.svg";
import contact from "../../icons/contact.svg";
import gallery from "../../icons/gallery.svg";
import blog from "../../icons/blog.svg";

const H2 = styled.h2`
  background-color: #333;
  color: white;
`;

const NavUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: grey;
  opacity: 0.6;
`;

const NavLi = styled.li`
  float: none;
  display: inline-block;
  margin-top: 2%;
  margin-bottom: 2%;
`;

const NavLink = styled(Link)`
  display: block;
  color: white;
  text-align: center;
  padding: 16px 16px;
  border-radius: 10%;
  text-decoration: none;

  &:hover {
    background-color: white;
    border-radius: 10%;
  }
`;

const IconImage = styled.img`
  height: 2rem;
`;

class Navbar extends Component {
  state = {};
  render() {
    return (
      <Router>
        <div>
          <NavUl>
            <NavLi>
              <NavLink to={"/"}>
                <IconImage src={profile} alt="profile" />
              </NavLink>
            </NavLi>
            <NavLi>
              <NavLink to={"/contact"}>
                <IconImage src={contact} alt="contact" />
              </NavLink>
            </NavLi>
            <NavLi>
              <NavLink to={"/gallery"}>
                <IconImage src={gallery} alt="gallery" />
              </NavLink>
            </NavLi>
            <NavLi>
              <NavLink to={"/blog"}>
                <IconImage src={blog} alt="blog" />
              </NavLink>
            </NavLi>
          </NavUl>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/profile" component={Home} />
            <Route path="/contact" component={Contact} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/blog" component={Blog} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Navbar;
