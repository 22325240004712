import React, { Component } from "react";
import logo from "../../logo.svg";

class Home extends Component {
  state = {};

  render() {
    return (
      <div>
        <header className="App-header">
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <h1>Kenneth Erasga</h1>
          {/* <p>
              Edit <code>src/App.js</code> and save to reload.
            </p>
            <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
              Learn React
            </a> */}
          <p>Welcome to my cave (under construction)</p>

          <p>I dabble in mathematics, gaming, machine learning, developing and automating...</p>
        </header>
          <div>
            <h1>Biography</h1>
          </div>
      </div>
    );
  }
}

export default Home;
